.descriptionWrapper {
    font-size: 16px;
    line-height: 24px;
}

.descriptionWrapper > * {
    font-size: 16px;
    line-height: 24px;
}

.descriptionWrapper figure {
    width: 80%;
    margin: 20px 0 0;
    padding: 10px;
    border: 1px solid #E7E7E1;
    border-radius: 12px;
}

.descriptionWrapper h1 {
    font-weight: bold;
    font-size: 1.25rem;
}

.descriptionWrapper ul {
    list-style-type: disc;
    padding-left: 20px;
}

.descriptionWrapper a {
    background: #F27928;
    border-radius: 12px;
    padding: 16px 32px;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    color: white;
    margin: 20px 0;
    transition: all .2s;
    display: block;
    width: min-content;
    white-space: nowrap;
}

.descriptionWrapper a:hover {
    background: #F39F66;
}

@media (max-width: 500px) {
    .descriptionWrapper figure {
        width: 100%;
    }

    .descriptionWrapper > * {
        font-size: 16px;
        line-height: 24px;
    }

    .descriptionWrapper a {
        width: 100%;
        display: block;
        margin: 0 0 -20px;
    }
}
