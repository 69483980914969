.slickWrapper :global(.slick-slide) {
    padding: 0 6px !important;
}

.slickWrapper :global(.slick-list) {
    margin: 0 -6px !important;
}

@media (max-width: 640px) {
    .slickWrapper :global(.slick-list) {
       overflow: initial;
    }
}

.slickWrapper :global(.slick-list > div) {
    margin-left:0;
}

.slickWrapper :global(.slick-arrow) {
    width: 28px;
    height: 28px;
    border: 1px solid #D6D6D6;
    border-radius: 8px;
    background-color: white;
    background-image: url('../../../public/icons/arrowRightBlack.png');
    background-size: 16px;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 1;
}

.slickWrapper :global(.slick-prev) {
    right: 35px;
    left: auto;
    top: -32px;
}

.slickWrapper :global(.slick-next) {
    right: 0;
    transform: rotate(180deg);
    top: -46px;
}

.slickWrapper :global(.slick-arrow:before) {
    content: none;
}

.slickWrapper :global(.slick-disabled) {
    opacity: 0.4;
}

.shine {
    position: relative;
    overflow: hidden;
}
.shine::before {
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
    content: "";
    display: block;
    height: 100%;
    left: -75%;
    position: absolute;
    top: 0;
    transform: skewX(-25deg);
    width: 50%;
    z-index: 2;
    animation: shine 5s infinite;
}

@keyframes shine {
    0% {
        left: -75%;
    }
    23% {
        left: 125%;
    }
    46% {
        left: -75%;
    }
    100% {
        left: -75%;
    }
}
