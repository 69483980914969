.categoryPostsWrapper :global(.slick-track) {
    display: flex !important;
}

.categoryPostsWrapper :global(.slick-slide) {
    height: inherit !important;
}

.categoryPostsWrapper :global(.slick-slide > div) {
    height: 100% !important;
}

.categoryPostsWrapper :global(.slick-dots) {
   display: flex!important;
    align-items: center;
    justify-content: center;
}

.categoryPostsWrapper :global(.slick-dots li) {
    width: auto;
    height: auto;
    margin: 0;
    padding: 2px;
}

.categoryPostsWrapper :global(.slick-dots li button) {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #D6D6D6;
}

.categoryPostsWrapper :global(.slick-dots li button::before) {
    content: none;
}

.categoryPostsWrapper :global(.slick-dots li.slick-active button) {
    width: 20px;
    background-color: #F27928;
    border-radius: 15px;
}
