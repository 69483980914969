.shine {
    position: relative;
    overflow: hidden;
}
.shine::before {
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
    content: "";
    display: block;
    height: 100%;
    left: -85%;
    position: absolute;
    top: 0;
    transform: skewX(-25deg);
    width: 50%;
    z-index: 2;
}

.shine:hover::before, .shine:focus::before {
    animation: shine 0.85s;
}

@keyframes shine {
    100% {
        left: 125%;
    }
}